import React from 'react';
import SocialMediaButtonsView from './SocialMediaButtonsView';

const SocialMediaButtonEdit = (props) => {
  return (
    <>
      <SocialMediaButtonsView />
    </>
  );
};
export default SocialMediaButtonEdit;
